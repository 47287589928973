import { render, staticRenderFns } from "./BendingProductionOutputKpiWidget.vue?vue&type=template&id=8ad18b1e&scoped=true"
import script from "./BendingProductionOutputKpiWidget.vue?vue&type=script&lang=ts"
export * from "./BendingProductionOutputKpiWidget.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ad18b1e",
  null
  
)

export default component.exports